import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    this.maxLength = this.element.getAttribute('maxlength') || 160;
    this.element.parentNode.classList.add('contains-counter');

    if (!this.element.nextElementSibling || !this.element.nextElementSibling.classList.contains('counter')) {
      this.counter = document.createElement('span');
      this.counter.classList.add('counter');
      this.counter.textContent = this.maxLength;
      this.element.parentNode.appendChild(this.counter);
    } else {
      this.counter = this.element.nextElementSibling;
    }

    this.element.addEventListener('input', this.updateCounter.bind(this));
    this.updateCounter();
  }

  updateCounter() {
    const remaining = this.maxLength - this.element.value.length;
    this.counter.textContent = remaining;
  }
}
